<template>
  <div style="background: #f6f6f7">
    <pcHeaders></pcHeaders>
    <div style="display: flex; justify-content: center; background: #fff; width: 100%; height: 100px; align-items: center">
      <img style="width: 1000px; height: 80px"
           src="../../assets/images/lc05.png"
           alt="" />
    </div>
    <div class="flex"
         style="justify-content: center; margin-top: 10px">
      <div class="banners">
        <div class="pay_methods_header flex"
             style="justify-content: space-between">
          <div style="display: flex">
            <div style="height: 50px">
              <img style=""
                   src="../../assets/images/pay_03.png"
                   alt="" />
            </div>

            <div style="font-size: 16px; margin-left: 15px; margin-top: 3px">
              <div>订单提交成功，请尽快付款！</div>
              <div>订单编号：{{ orderInfo.order.ordNo }}</div>
            </div>
          </div>

          <div style="font-size: 16px; margin-left: 15px; margin-top: 3px">
            <div>
              剩余支付时间：<span style="color: #ffa45d"> {{ hours }}小时{{ minutes }}分{{ seconds }}秒</span> 
            </div>
            <div style="text-align: right">
              应付金额 :
              <span style="font-size: 18px; color: #f02311; font-weight: bold">￥{{ orderInfo.order.ordMoneysum.toFixed(2) }}</span>元
            </div>
          </div>
        </div>

        <div style="margin-top: 15px;display: flex;align-items: center;">
          <div>
            温馨提示：如您需要发票可提前填写发票信息,请点击我要开票按钮。
          </div>
          <div>
            <el-button type="primary" size="mini" @click="showKp">
              {{ btnText }}
            </el-button>
          </div>
        </div>

        <div style="margin-top: 15px;display: flex;justify-content: center;" v-if="kpFalg">
          <div>
            <div style="display: flex; align-items: center">
              <div style="font-size: 16px">&emsp;&emsp;发票抬头:</div>
              <el-input style="width: 260px; margin-left: 20px"
                        v-model="invoiceInfo.invoiceHeader"
                        placeholder="请输入发票抬头"></el-input>
            </div>

            <div style="display: flex; align-items: center; margin-top: 15px">
              <div style="font-size: 16px">纳税人识别号:</div>
              <el-input style="width: 260px; margin-left: 20px"
                        v-model="invoiceInfo.identifyNum"
                        placeholder="请输入纳税人识别号"></el-input>
            </div>

            <div style="display: flex; align-items: center; margin-top: 15px">
              <div style="font-size: 16px">邮箱（选填）:</div>
              <el-input style="width: 260px; margin-left: 20px"
                        v-model="invoiceInfo.invoiceEmail"
                        placeholder="请输入邮箱"></el-input>
            </div>
            <div style="margin-top: 5px; color: #2878ff">备注：发票会发送到此邮箱，或者到订单管理页面点击下载。</div>

            <div style="padding-left: 0; text-align: left; margin-top: 20px; font-size: 14px; color: #999999; word-wrap: ">
              <div>
                填写要求请见<a style="color: #ff8000"
                   target="_blank"
                   href="http://www.chinatax.gov.cn/n810341/n810755/c2644618/content.html">《国家税务总局关于增值税发票开具有关问题的公告》</a>
              </div>
            </div>
            <p style="padding-left: 0; text-align: left; margin-top: 5px; font-size: 14px; color: #999999">
              开具增值税普通发票，请于缴费当年领取，不支持跨年度领取
            </p>
          </div>
        </div>

        <div class="flex"
             style="font-size: 16px; justify-content: space-between; margin-top: 30px">
          <div class="flex">
            <div>
              <img style="margin-top: 2px"
                   src="../../assets/images/02_03.png"
                   alt="" />
            </div>
            <div style="margin-left: 5px; font-size: 18px">支付方式</div>
          </div>
        </div>

        <div class="pay_methods_header flex"
             style="
            justify-content: space-between;
            padding: 0 30px;
            background: #fff;
            border: 1px solid #f3f3f3;
            margin-top: 10px;
            -webkit-box-shadow: 3px 3px 3px #e0e0e0;
            -moz-box-shadow: 3px 3px 3px #e0e0e0;
            box-shadow: 3px 3px 3px #e0e0e0;
            align-items: center;
          ">
          <div style="display: flex; align-items: center">
            <div>
              <el-radio v-model="radio"
                        label="1"
                        style="align-items: center; display: flex">
                <img src="../../assets/images/pay1_05.png"
                     alt="" />
              </el-radio>
            </div>
            <div style="width: 1px; height: 15px; background: #e0e0e0; margin-left: 50px"></div>
            <el-radio v-model="radio"
                      style="align-items: center; display: flex; margin-left: 50px"
                      label="2"><img src="../../assets/images/pay1_03.png"
                   alt="" /></el-radio>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 35px">
          <div></div>
          <div class="pay_btn"
               @click="pay">确认支付</div>
        </div>
      </div>
    </div>
    <div style="height: 80px"></div>
    <el-dialog title="温馨提示："
               :visible.sync="payShow"
               width="30%"
               v-if="this.radio == 1">
      <div style="text-align: center; font-size: 18px">是否支付成功？</div>
      <div style="text-align: center; color: #e33737">(支付完成前，请不要关闭此支付验证窗口)</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="payShow = false">否</el-button>
        <el-button type="primary"
                   @click="toTradeQueryZFB">是</el-button>
      </span>
    </el-dialog>

    <el-dialog title="温馨提示："
               :visible.sync="payShow"
               width="30%"
               v-else>
      <div style="text-align: center; font-size: 18px">是否支付成功？</div>
      <div style="text-align: center; color: #e33737">(支付完成前，请不要关闭此支付验证窗口)</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="payShow = false">否</el-button>
        <el-button type="primary"
                   @click="dz">是</el-button>
      </span>
    </el-dialog>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';

import { toPayOrder, payEnd, toTradeQueryZFB, toTradeQueryWX, doGenerateInvoiceNew } from '@/api/pay.js';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      orderInfo: {
        order: {
          ordNo: ''
        }
      }, //订单核对数据
      radio: '1',
      totalSeconds: 0,
      imerId: null, // 存放定时器ID
      payShow: false,
      timerId: null,
      invoiceInfo: {
        invoiceHeader: '',
        identifyNum: '',
        invoiceEmail: ''
      },
      btnText:'我要开票',
      kpFalg:false,
    };
  },
  // 监听属性 类似于data概念
  computed: {
    hours() {
      const h = Math.floor(this.totalSeconds / (60 * 60));
      if (h >= 24) {
        this.stopCountdown();
      }
      return h;
    },
    minutes() {
      const m = Math.floor((this.totalSeconds % (60 * 60)) / 60);
      return m > 59 ? '59' : m;
    },
    seconds() {
      const s = Math.floor(this.totalSeconds % 60);
      return s > 59 ? '59' : s;
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //开票按钮
    showKp() {
      this.kpFalg = !this.kpFalg
      if (!this.kpFalg) {
        this.btnText = '我要开票'
      }else {
        this.btnText = '收起'
      }
    },
    //弹框对账
    dz() {
      if (this.radio == 1) {
        this.toTradeQueryZFB();
      } else {
        this.toTradeQueryWX();
      }
    },
    //支付宝对账
    toTradeQueryZFB() {
      toTradeQueryZFB({ orderId: this.orderInfo.order.id }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '2') {
          this.$confirm('支付失败', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.payShow = false;
          });
        } else {
          this.payShow = false;
          this.$message({
            message: '支付成功！',
            type: 'success'
          });
          this.$router.push('/studyMain');
        }
      });
    },

    //微信对账
    toTradeQueryWX() {
      toTradeQueryWX({ orderId: this.orderInfo.order.id }).then(res => {
        console.log(res.data.data.result);
        if (res.data.data.result == '2') {
          this.$confirm('支付失败', '温馨提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }).then(() => {
            this.payShow = false;
          });
        } else {
          this.payShow = false;
          this.$message({
            message: '支付成功！',
            type: 'success'
          });
          this.$router.push('/studyMain');
        }
      });
    },

    startCountdown() {
      this.timerId = setInterval(() => {
        --this.totalSeconds;
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timerId);
    },
    //立即支付
    pay() {
      if (this.invoiceInfo.invoiceHeader == '' && this.invoiceInfo.identifyNum == '' && this.invoiceInfo.invoiceEmail == '') {
        let payData = {
          userId: this.orderInfo.userId,
          orderId: this.orderInfo.order.id,
          ordPaytype: this.radio == 1 ? '06' : '0903'
        };
        this.$confirm('一旦缴费成功，正常情况下不允许退款，继续请点击确定，终止请点击取消', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            payEnd(payData).then(res => {
              if (res.data.data.code == '1' && payData.ordPaytype == '06') {
                let routeData = this.$router.resolve({
                  path: '/zfb',
                  query: {
                    data: res.data.data.sHtmlText
                  }
                });
                window.open(routeData.href, '_blank');
                this.payShow = true;
              } else if (res.data.data.code == '1' && payData.ordPaytype == '0903') {
                let routeData = this.$router.resolve({
                  path: '/vx',
                  query: {
                    codeUrl: res.data.data.codeUrl,
                    orderNo: res.data.data.orderNo,
                    orderId: this.orderInfo.order.id
                  }
                });
                window.open(routeData.href, '_blank');
                this.payShow = true;
              } else if (res.data.data.code == '2') {
                this.$message({
                  message: '订单已支付！',
                  type: 'success'
                });
                this.$router.push('/studyMain');
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
      } else {
        if (this.invoiceInfo.invoiceHeader == '') {
          this.$message('请填写发票抬头！');
          return false;
        } else if (this.invoiceInfo.identifyNum == '') {
          this.$message('请填写纳税人识别号！');
          return false;
        }
        const reg = /^[0-9a-zA-Z\(\)\（\）\u4e00-\u9fa5]{0,50}$/;
        if (!reg.test(this.invoiceInfo.invoiceHeader)) {
          this.$message('请输入正确的发票抬头');
          return false;
        }
        const reg1 = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/;
        if (!reg1.test(this.invoiceInfo.identifyNum)) {
          this.$message('请输入正确的纳税人识别号');
          return false;
        }
        if (this.invoiceInfo.invoiceEmail !== '') {
          const regEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
          if (!regEmail.test(this.invoiceInfo.invoiceEmail)) {
            this.$message('请输入正确的邮箱');
            return false;
          }
        }
        this.invoiceInfo.orderId = this.orderInfo.order.id;
        doGenerateInvoiceNew(this.invoiceInfo).then(res => {});
        let payData = {
          userId: this.orderInfo.userId,
          orderId: this.orderInfo.order.id,
          ordPaytype: this.radio == 1 ? '06' : '0903'
        };
        this.$confirm('一旦缴费成功，正常情况下不允许退款，继续请点击确定，终止请点击取消', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            payEnd(payData).then(res => {
              if (res.data.data.code == '1' && payData.ordPaytype == '06') {
                let routeData = this.$router.resolve({
                  path: '/zfb',
                  query: {
                    data: res.data.data.sHtmlText
                  }
                });
                window.open(routeData.href, '_blank');
                this.payShow = true;
              } else if (res.data.data.code == '1' && payData.ordPaytype == '0903') {
                let routeData = this.$router.resolve({
                  path: '/vx',
                  query: {
                    codeUrl: res.data.data.codeUrl,
                    orderNo: res.data.data.orderNo,
                    orderId: this.orderInfo.order.id
                  }
                });
                window.open(routeData.href, '_blank');
                this.payShow = true;
              } else if (res.data.data.code == '2') {
                this.$message({
                  message: '订单已支付！',
                  type: 'success'
                });
                this.$router.push('/studyMain');
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
      }
    },
    //生成订单
    toPayOrder() {
      toPayOrder({ orderId: this.$route.query.orderId }).then(res => {
        this.orderInfo = res.data.data;
        console.log(this.orderInfo.hour);
        this.totalSeconds = Number(this.orderInfo.hour) * 60 * 60 + Number(this.orderInfo.min) * 60 + Number(this.orderInfo.s);
        this.startCountdown();
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toPayOrder();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */

.flex {
  display: flex;
}
.banners {
  background: #fff;
  padding: 20px 10px;
  width: 1200px;
  font-family: Regular;
  padding-bottom: 20px;
  .pay_methods_header {
    height: 93px;
    border: 2px solid #e3e3e3;
    width: 100%;
    padding: 20px;
  }
  .pay_btn {
    width: 121px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: #fd9d57;
    color: #fff;
    cursor: pointer;
  }
}
* {
  font-family: Regular;
}
</style>
